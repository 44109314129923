<template>
  <vue-final-modal
    v-model="showModal"
    classes="modal-container"
    content-class="modal-content"
    :click-to-close="false"
  >
    <button class="modal__close btn" @click="closeModal">
      <i class="bi bi-x"></i>
    </button>
    <span class="modal__title">{{ formTitle }}</span>
    <div class="modal__content py-2 px-2">
      <form @submit.prevent="submitForm" class="form">
        <div class="col-md-12 mb-3">
          <label for="nama_produk" class="form-label">Nama Produk</label>
          <input
            class="form-control"
            type="text"
            v-model="editedItem.nama_produk"
            :class="{ 'is-invalid': $v.editedItem.nama_produk.$error }"
          />
          <div v-if="$v.editedItem.nama_produk.$error" class="invalid-feedback">
            <span v-if="!$v.editedItem.nama_produk.required"
              >Nama Produk harus di isi.</span
            >
            <span v-if="!$v.editedItem.nama_produk.min_length"
              >Minimal 2 karakter.</span
            >
          </div>
        </div>
        <div class="col-md-12 mb-3">
          <label for="nama_vendor" class="form-label">Nama Vendor</label>
          <input
            class="form-control"
            type="text"
            v-model="editedItem.nama_vendor"
            :class="{ 'is-invalid': $v.editedItem.nama_vendor.$error }"
          />
          <div v-if="$v.editedItem.nama_vendor.$error" class="invalid-feedback">
            <span v-if="!$v.editedItem.nama_vendor.required"
              >Nama Vendor harus di isi.</span
            >
            <span v-if="!$v.editedItem.nama_vendor.min_length"
              >Minimal 2 karakter.</span
            >
          </div>
        </div>
        <div class="col-md-12 mb-3">
          <label for="ukuran" class="form-label">Ukuran</label>
          <input class="form-control" type="text" v-model="editedItem.ukuran" />
        </div>
        <div class="col-md-12 mb-3">
          <label for="jenis_bahan" class="form-label">Jenis Bahan</label>
          <input
            class="form-control"
            type="text"
            v-model="editedItem.jenis_bahan"
          />
        </div>
        <div class="col-md-12 mb-3">
          <label for="harga" class="form-label">Harga</label>
          <input
            class="form-control"
            type="text"
            v-model="editedItem.harga"
            :class="{ 'is-invalid': $v.editedItem.harga.$error }"
          />
          <div v-if="$v.editedItem.harga.$error" class="invalid-feedback">
            <span v-if="!$v.editedItem.harga.required"
              >Harga harus di isi.</span
            >
          </div>
        </div>
        <div class="col-md-12 mb-3">
          <label for="foto_produk_1_path" class="form-label"
            >Foto Produk 1</label
          >
          <input
            class="form-control"
            type="file"
            @change="changeFotoProduk1"
            accept="image/png, image/jpeg"
          />
        </div>
        <div class="col-md-12 mb-3">
          <label for="foto_produk_2_path" class="form-label"
            >Foto Produk 2</label
          >
          <input
            class="form-control"
            type="file"
            @change="changeFotoProduk2"
            accept="image/png, image/jpeg"
          />
        </div>
      </form>
    </div>
    <div class="modal__action mt-3">
      <button class="btn btn-primary mx-2" @click="submitForm">Simpan</button>
      <button class="btn" @click="closeModal">Batal</button>
    </div>
  </vue-final-modal>
</template>

<script>
import { required, minLength } from "vuelidate/lib/validators";
import { VueFinalModal } from "vue-final-modal";
export default {
  components: {
    VueFinalModal,
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    editedIndex: {
      type: Number,
      default: -1,
    },
    editedId: {
      type: Number,
      default: -1,
    },
    editedItem: {
      type: Object,
      required: true,
    },
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Data Baru" : "Edit Data";
    },
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    async submitForm() {
      this.$v.$touch();
      if (!this.$v.$invalid) this.$emit("save");
    },
    changeFotoProduk1(e) {
      const selectedFile = e.target.files[0];
      this.editedItem.foto_produk_1_path = selectedFile;
    },
    changeFotoProduk2(e) {
      const selectedFile = e.target.files[0];
      this.editedItem.foto_produk_2_path = selectedFile;
    },
  },
  validations: {
    editedItem: {
      nama_produk: {
        required,
        minLength: minLength(2),
      },
      nama_vendor: {
        required,
        minLength: minLength(2),
      },
      harga: {
        required,
      },
    },
  },
};
</script>

<style scoped>
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

::v-deep .modal-content {
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
  max-width: 600px;
}
</style>
