<template>
  <tr>
    <td width="30%">{{ data.nama_produk }}</td>
    <td>{{ data.nama_vendor }}</td>
    <td>{{ data.ukuran }}</td>
    <td>{{ rupiah }}</td>
    <td width="20%">
      <div class="btn-group" role="group" aria-label="Action menu">
        <button type="button" class="btn btn-white btn-sm" @click="show(data)">
          <i class="bi bi-eye"></i> Lihat Detail
        </button>
        <button
          type="button"
          class="btn btn-secondary btn-sm"
          @click="edit(data)"
        >
          <i class="bi bi-pencil"></i> Edit
        </button>
        <button
          type="button"
          class="btn btn-danger btn-sm"
          @click="confirmDelete(data.id)"
        >
          <i class="bi bi-trash"></i> Hapus
        </button>
      </div>
    </td>
  </tr>
</template>

<script>
export default {
  name: "TableItem",
  props: {
    data: {
      type: Object,
    },
  },
  computed: {
    rupiah() {
      return new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",
      }).format(this.data.harga);
    },
  },
  methods: {
    localDate(date) {
      return date.substring(0, 10);
    },
    edit(data) {
      this.$emit("edit", data);
    },
    show(data) {
      this.$emit("show", data);
    },
    confirmDelete(id) {
      this.$emit("delete", id);
    },
  },
};
</script>

<style scoped></style>
