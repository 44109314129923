<template>
  <vue-final-modal
    v-model="showModal"
    classes="modal-container"
    content-class="modal-content"
    :click-to-close="false"
  >
    <button class="modal__close btn" @click="closeModal">
      <i class="bi bi-x"></i>
    </button>
    <span class="modal__title">Lihat Detail</span>
    <div class="modal__content py-2 px-2">
      <div class="row">
        <div class="col-md-3" v-if="foto_produk_1">
          <img
            :src="'data:image/png;base64,' + foto_produk_1"
            width="100%"
            alt="Foto Produk 1"
          />
        </div>
        <div class="col-md-3" v-if="foto_produk_2">
          <img
            :src="'data:image/png;base64,' + foto_produk_2"
            width="100%"
            alt="Foto Produk 2"
          />
        </div>
        <div :class="foto_produk_2 ? 'col-md-6' : 'col-md-9'">
          <table class="table">
            <tr>
              <td>Nama Produk</td>
              <td>{{ editedItem.nama_produk }}</td>
            </tr>
            <tr>
              <td>Nama Vendor</td>
              <td>{{ editedItem.nama_vendor }}</td>
            </tr>
            <tr>
              <td>Ukuran</td>
              <td>{{ editedItem.ukuran }}</td>
            </tr>
            <tr>
              <td>Jenis Bahan</td>
              <td>{{ editedItem.jenis_bahan }}</td>
            </tr>
            <tr>
              <td>Harga</td>
              <td>{{ harga }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </vue-final-modal>
</template>

<script>
import { VueFinalModal } from "vue-final-modal";
export default {
  components: {
    VueFinalModal,
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    editedIndex: {
      type: Number,
      default: -1,
    },
    editedId: {
      type: Number,
      default: -1,
    },
    editedItem: {
      type: Object,
      required: true,
    },
    foto_produk_1: String,
    foto_produk_2: String,
  },
  computed: {
    harga() {
      return new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",
      }).format(this.editedItem.harga);
    },
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
  },
  async mounted() {
    // Load Foto Produk 1
    if (this.editedItem.foto_produk_1_path) {
      const response = await this.axios.get(
        this.editedItem.foto_produk_1_path,
        {
          responseType: "arraybuffer",
        }
      );

      const buffer = Buffer.from(response.data).toString("base64");
      this.foto_produk_1 = buffer;
    }
  },
};
</script>

<style scoped>
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

::v-deep .modal-content {
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
  max-width: 1024px;
}
</style>
